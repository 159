import React from 'react';

import Link from 'atoms/Link';
import SEO from 'seo';
import TextLayout from 'templates/TextLayout';

const ImprintPage = () => (
  <TextLayout title="Imprint">
    <SEO
      title="Imprint"
      description="Here you can find the imprint information of lyno GmbH"
    />
    <p>All the legal documents governing the services provided by lyno are collected here.</p>
    <p>Information according to section 5 TMG (German Telemedia Act):</p>
    <p>
      lyno GmbH<br />
      Rather Str. 25<br />
      40476 Düsseldorf<br />
      Germany
    </p>

    <p>
      Represented by:<br />
      Paul Tiedtke<br />
      Giovanni Cascio
    </p>

    <p>
      Contact:<br />
      Email: info(at)eventstrive.com
    </p>

    <p>
      Register:<br />
      Amtsgericht Düsseldorf HRB 89127
    </p>

    <p>
      <Link
        to="https://ec.europa.eu/consumers/odr/"
        rel="noopener noreferrer"
        target="_blank"
      >
        Here is the link
      </Link>
      {' '}
      to the platform of the European Commission according to Regulation on consumer Online Dispute Resolution.
      Lyno does currently not use the Online Dispute Resolution platform for any complaints.
    </p>
  </TextLayout>
);

export default ImprintPage;
